import * as React from "react";
import "@fontsource/lato";
import ColorList from "./components/ColorList";
import CustomColorPicker from "./components/CustomColorPicker";


const IndexPage = () => {
  const [color, setColor] = React.useState(null);

  return (
    <div id="wrapper">
      <main id="main">
        <div className="inner">
          <title>A Color Needs A Name.</title>
          <h1>
            <a href="/">A Color Needs A Name.</a>
          </h1>
          <section>
            <h2>What is it?</h2>
            <p>
              A Color Needs A Name. is a collection of 1,567 images of named
              colors stored on the Ethereum blockchain. Each color has a unique
              name displayed in the bottom right corner.
            </p>
          </section>
          <section>
            <h2>How to get a Color?</h2>
            <p>
              Every day 10 new random colors will be made available on the{" "}
              <a href="https://opensea.io/collection/a-color-needs-a-name">
                OpenSea Marketplace
              </a>
              .
            </p>
          </section>
          <section>
            <CustomColorPicker color={color || "#FFFFFF"} onChange={setColor} />
            <ColorList selectedColor={color} onReset={() => setColor(null)} />
          </section>
        </div>
      </main>
    </div>
  );
};

export default IndexPage;
